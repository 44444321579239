@mixin border-radius($radius) {
  	-webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin transform($transform...) {
  	-webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}
@mixin transition($transition...) {
  	-webkit-transition: $transition;
   	-moz-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}
@mixin transitionSet($transition...) {
  	-webkit-transition: $transition;
   	-moz-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}
@mixin width($width) {
  	width: $width;
  	width: -webkit-$width;
}
@mixin height($height) {
  	height: $height;
  	height: -webkit-$height;
}
@mixin appearance($appearance) {
   	appearance: $appearance;
    -webkit-appearance: $appearance;
}
@mixin box-shadow($box-shadow...) {
   	box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    -ms-box-shadow: $box-shadow;
}
@mixin box-sizing($box-sizing...) {
   	box-sizing: $box-sizing;
    -webkit-box-sizing: $box-sizing;
    -moz-box-sizing: $box-sizing;
    -ms-box-sizing: $box-sizing;
}
@mixin flex(){
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox; 
	display: -webkit-flex;
	display: flex; 
}

$mtiny: 360px;
$mmobi: 540px;
$mtablet: 768px;
$mmid: 1024px;
$mdesktop: 1440px;

@mixin tiny {
    @media (max-width: #{$mtiny}) {
      @content;
    }
}
@mixin mobi {
    @media (max-width: #{$mmobi}) {
      @content;
    }
}
@mixin tablet {
    @media (max-width: #{$mtablet}) {
      @content;
    }
}
@mixin mid {
    @media (max-width: #{$mmid}) {
      @content;
    }
}
@mixin desktop {
    @media (max-width: #{$mdesktop}) {
      @content;
    }
}
